import React, { useEffect } from "react";

import { Link } from "gatsby";
import { connect } from "react-redux";
import slugify from "slugify";
import { GatsbyImage } from "gatsby-plugin-image";

import { getCartTotal } from "../../services";
import {
  removeFromCart,
  incrementQty,
  decrementQty,
} from "../../reducers/cart";

function CartPage(props) {
  const { cartlist, removeFromCart, incrementQty, decrementQty } = props;

  useEffect(() => {
    cartlist.map((item, index) => {
      if (
        document.querySelector(
          `#quantity-input-wrapper-${index} .input-group input`
        )
      ) {
        document.querySelector(
          `#quantity-input-wrapper-${index} .input-group input`
        ).value = item.qty;
      }
      return item;
    });
  }, [cartlist]);

  // function onChangeQty(e, productId) {
  //   props.changeQty(
  //     productId,
  //     e.currentTarget.querySelector('input[type="number"]').value
  //   );
  // }


  return (
    <div>
      <div className="page-content">
        <div className="cart">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <table role="none" className="table table-cart table-mobile">
                  <thead>
                    <tr>
                      <th style={{color: "black"}}>Product</th>
                      <th style={{color: "black"}}>Price</th>
                      <th style={{color: "black"}}>Quantity</th>
                      <th style={{color: "black"}}>Total</th>
                      <th style={{color: "black"}}>{""}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {cartlist.length > 0 ? (
                      cartlist.map((item, index) => (
                        <tr key={"cart-item-" + index}>
                          <td className="product-col">
                            <div className="product">
                              <figure aria-labelledby={`${item._id.substring(0,20)}`} className="product-media">
                                <Link
                                    to={`/products/${item.seoMetaData?.slug ||
                                      slugify(item.title).toLowerCase()
                                      }/`}
                                      alt={item.title}
                                >
                                  <GatsbyImage
                                  
                                  alt={`image of product ${item.title}`}
                                    image={item.thumbnail.childImageSharp?.gatsbyImageData}
                                    className="product-image" />
                                </Link>
                              </figure>

                              <h3 className="product-title" id={`${item._id.substring(0,20)}`}>
                                <Link
                                    to={`/products/${item.seoMetaData?.slug ||
                                      slugify(item.title).toLowerCase()
                                      }/`}
                                >
                                  {item.title}
                                </Link>
                              </h3>
                            </div>
                          </td>

                          <td className="price-col">
                            $
                            {0 < item.discount
                              ? item.oldprice.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : item.price.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </td>

                          <td
                            className="quantity-col"
                            id={"quantity-input-wrapper-" + index}
                          >
                            <div
                              className="cart-product-quantity input-spinner"
                              // onClick={(e) => onChangeQty(e, item.id)}
                            >
                              <label htm>Quantity</label>
                              <div className="input-group-prepend">
                                <button
                                  onClick={() => decrementQty(item._id)}
                                  style={{ minWidth: "26px" }}
                                  className="btn btn-decrement btn-spinner"
                                  type="button"
                                  disabled={item.quantity === 1}
                                  aria-label="decrement quantity"
                                >
                                  <i className="icon-minus"></i>
                                </button>
                              </div>
                              <input
                                type="text"
                                style={{ textAlign: "center" }}
                                class="form-control "
                                // onClick={(e) => onChangeQty(e, item._id)}
                                value={item.quantity || "0"}
                                required
                                placeholder=""
                                aria-label="quantity"
                              />
                              <div className="input-group-append">
                                <button
                                  onClick={() => incrementQty(item._id)}
                                  style={{ minWidth: "26px" }}
                                  className="btn btn-increment btn-spinner"
                                  type="button"
                                  disabled={item.quantity >= item.stock}
                                  aria-label="increment"

                                >
                                  <i className="icon-plus"></i>
                                </button>
                              </div>
                              {/* <input
                                      type="number"
                                      className="form-control"
                                      defaultValue={parseInt(item.quantity)}
                                      min="1"
                                    //   max={parseInt(item.stock)}
                                      step="1"
                                      data-decimals="0"
                                      required
                                    /> */}
                            </div>
                          </td>

                          <td className="total-col">
                            $
                            {Number.parseFloat(item.quantity * item.price)
                              .toFixed(2)
                              .toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </td>

                          <td className="remove-col">
                            <button
                              className="btn-remove"
                              aria-label="remove"
                              onClick={(e) => removeFromCart(item._id)}
                            >
                              <i className="icon-close"></i>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <p className="pl-2 pt-1 pb-1">
                            {" "}
                            No Products in Cart{" "}
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <aside aria-label="cart" className="col-lg-3">
                <div className="summary summary-cart">
                  <h3 className="summary-title">Cart Total</h3>

                  <table role="none" className="table table-summary">
                    <tbody>
                      <tr className="summary-subtotal">
                        <td>Subtotal:</td>
                        <td>
                          ${" "}
                          {cartlist.length > 0
                            ? Number.parseFloat(
                                cartlist.reduce(
                                  (acc, { quantity, price }) =>
                                    acc + quantity * price,
                                  0
                                )
                              ).toFixed(2)
                            : "0"}
                          {/* {total.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })} */}
                        </td>
                      </tr>
                      {/* <tr className="summary-shipping">
                        <td>Shipping:</td>
                        <td>&nbsp;</td>
                      </tr> */}

                      {/* <tr className="summary-total">
                        <td>Total:</td>
                        <td>
                          $
                          {(
                                  total + shippingPrice[shipping]
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                        </td>
                      </tr> */}
                    </tbody>
                  </table>

                  <Link
                    to="/checkout/"
                    className="btn btn-outline-primary-2 btn-order btn-block"
                    style={{color: '#000'}}
                  >
                    PROCEED TO CHECKOUT
                  </Link>
                </div>

                <Link
                  to={`/collections`}
                  className="btn btn-outline-dark-2 btn-block mb-3"
                >
                  <span>CONTINUE SHOPPING</span>
                  <i className="icon-refresh"></i>
                </Link>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const mapStateToProps = (state) => ({
  cartlist: state.cartlist.items,
  total: getCartTotal(state.cartlist.items),
  prevShip: state.cartlist.shipping,
});

export default connect(mapStateToProps, {
  removeFromCart,
  incrementQty,
  decrementQty,
})(CartPage);
